import { ACTION, OPERATIONS } from "constants/actions";
import moment from "moment";

const constructDateRange = (values) => {
  let dateRange = values.dateRange;
  const isDateRangePopulated = Boolean(
    values.dateRange.lower || values.dateRange.upper
  );

  if (isDateRangePopulated) {
    dateRange = {
      lower: moment(values.dateRange.lower).format("YYYY-MM-DD"),
      upper: moment(values.dateRange.upper).format("YYYY-MM-DD"),
    };
  } else {
    if (values.datetimePerformed && values.datetimePerformedEnd) {
      dateRange = null;
    }
  }

  return dateRange;
};

const constructTimeTaken = (values) => {
  let timeTaken = values.timeTaken || 0;
  if (values?.equipment?.length > 0) {
    timeTaken = values.equipment.reduce((acc, curr) => acc + curr.duration, 0);
  }
  return timeTaken;
};

const removeUnnecessaryFields = (values) => {
  const {
    shouldCheckEnemyId,
    stressType,
    status,
    initialStatus,
    ...finalValues
  } = values;

  return finalValues;
};

const turnAnalysisQuantitiesToNumbers = (analysis) => {
  if (typeof analysis !== "object") return analysis;

  const finalAnalysis = Object.entries(analysis).reduce((acc, [key, value]) => {
    const quantity = Number(value.quantity);
    return {
      ...acc,
      [key]: {
        ...value,
        quantity,
      },
    };
  }, {});
  return finalAnalysis;
};

const constructWaterPayload = (values) => {
  const dateRange = constructDateRange(values);
  const timeTaken = constructTimeTaken(values);

  return {
    ...values,
    dateRange,
    timeTaken,
  };
};

const constructFertilizePayload = (values) => {
  const dateRange = constructDateRange(values);
  const timeTaken = constructTimeTaken(values);

  return {
    ...values,
    dateRange,
    timeTaken,
  };
};
const constructDrugPayload = (values) => {
  const dateRange = constructDateRange(values);
  const timeTaken = constructTimeTaken(values);

  return {
    ...values,
    dateRange,
    timeTaken,
  };
};

const constructDiagnosePayload = (values, helpers = {}) => {
  const dateRange = constructDateRange(values);
  const { diagnoseOperation } = helpers;
  const { quantity, quantityUnits, analysis, ...diagnoseAction } =
    values?.diagnoseAction || {};

  // Handle analysis operation
  if (
    diagnoseOperation === OPERATIONS.DIAGNOSE.SOIL_SAMPLING ||
    diagnoseOperation === OPERATIONS.DIAGNOSE.LEAF_ANALYSIS
  ) {
    return {
      ...values,
      dateRange,
      diagnoseAction: {
        ...diagnoseAction,
        analysis: turnAnalysisQuantitiesToNumbers(analysis),
      },
    };
  }

  // handle insect operation
  if (diagnoseOperation === OPERATIONS.DIAGNOSE.INSECT_MONITORING) {
    return {
      ...values,
      dateRange,
      diagnoseAction: {
        ...diagnoseAction,
        quantity,
        quantityUnits,
      },
    };
  }

  return {
    ...values,
    diagnoseAction,
    dateRange,
  };
};
const constructOtherPayload = (values, helpers = {}) => {
  const dateRange = constructDateRange(values);
  const timeTaken = constructTimeTaken(values);

  const { otherOperation } = helpers;

  const {
    quantity,
    quantityUnits,
    ploughingType,
    mulchType,
    ...restOtherAction
  } = values.otherAction;

  if (otherOperation === OPERATIONS.OTHER.HARVEST) {
    return {
      ...values,
      otherAction: {
        ...restOtherAction,
        quantity,
        quantityUnits,
      },
      dateRange,
      timeTaken,
    };
  }
  if (otherOperation === OPERATIONS.OTHER.PLOWING) {
    return {
      ...values,
      otherAction: {
        ...restOtherAction,
        ploughingType,
      },
      dateRange,
      timeTaken,
    };
  }
  if (otherOperation === OPERATIONS.OTHER.MULCH) {
    return {
      ...values,
      otherAction: {
        ...restOtherAction,
        mulchType,
      },
      dateRange,
      timeTaken,
    };
  }
  return {
    ...values,
    dateRange,
    timeTaken,
  };
};

export const constructActionPayload = (type, values, helpers) => {
  const reducedValues = removeUnnecessaryFields(values);
  switch (type) {
    case ACTION.watering:
      return constructWaterPayload(reducedValues, helpers);
    case ACTION.fertilize:
      return constructFertilizePayload(reducedValues, helpers);
    case ACTION.drug:
      return constructDrugPayload(reducedValues, helpers);
    case ACTION.diagnose:
      return constructDiagnosePayload(reducedValues, helpers);
    case ACTION.other:
      return constructOtherPayload(reducedValues, helpers);
    default:
      return;
  }
};
