import { SupersetDto } from "api/dtos/SupersetDto";
import axiosInstance from "api/utils/axiosInstance";
import { URLS } from "api/utils/baseUrls";

const getSupersetConfig = async (userVatNumber, applicationYear) => {
  try {
    const res = await axiosInstance.get(URLS.superset, {
      params: {
        tax_number: userVatNumber,
        year: applicationYear,
      },
    });
    const config = SupersetDto.setup(res.data);
    return { data: config };
  } catch (error) {
    console.error("Error fetching superset token", error);
    throw error;
  }
};

export const supersetApi = {
  fetchConfig: getSupersetConfig,
};
