import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { FETCH_STATE } from "constants/fetchState";
import { OPEKEPE_SYNC_SCOPE } from "constants/opekepe";
import "./opekepe-sync.css";
import OpekepeSyncStep from "./OpekepeSyncStep";
import useOpekepeSyncSteps from "./useOpekepeSyncSteps";
import SyncKydLinkSection from "./SyncKydLinkSection";
import LoadingButton from "../LoadingButton/LoadingButton";

const OpekepeSync = ({
  user,
  scope,
  isDisabled,
  isFullWidth,
  onSyncSuccess,
}) => {
  const { t } = useTranslation();

  const [isSynced, setIsSynced] = useState(false);
  const handleSyncSuccess = useCallback(() => {
    setIsSynced(true);
    onSyncSuccess?.();
  }, [onSyncSuccess]);

  const {
    steps,
    buttonText,
    error,
    fetchState,
    opekepeLink,
    onSubmit,
    onIcognitoSynced,
  } = useOpekepeSyncSteps({
    user,
    scope,
    onSyncSuccess: handleSyncSuccess,
  });

  const isStep2Disabled = steps[0].state !== FETCH_STATE.SUCCESS;
  const isButtonDisabled =
    isDisabled || isSynced || fetchState === FETCH_STATE.LOADING;

  const isKydIcognitoLink =
    scope === OPEKEPE_SYNC_SCOPE.KYD && steps[0].state === FETCH_STATE.IDLE;

  return (
    <div
      className={clsx("opekepe-sync", {
        fullwidth: isFullWidth,
      })}
    >
      <OpekepeSyncStep
        index={1}
        title={t("OpekepeSync.Account.Title")}
        state={steps[0]}
        isDisabled={isDisabled}
        loadingText={t("OpekepeSync.Account.Loading")}
        errorText={error}
        successText={t("OpekepeSync.Account.Success")}
        content={[
          {
            key: "name",
            title: t("OpekepeSync.Name"),
          },
          {
            key: "vat",
            title: t("OpekepeSync.Vat"),
          },
        ]}
      />
      <OpekepeSyncStep
        index={2}
        title={t("OpekepeSync.Fields.Title")}
        isDisabled={isDisabled || isStep2Disabled}
        state={steps[1]}
        loadingText={t("OpekepeSync.Fields.Loading")}
        errorText={error}
        successText={t("OpekepeSync.Fields.Success")}
        content={[
          {
            key: "fields",
            title: t("OpekepeSync.TotalFields"),
          },
          {
            key: "crops",
            title: t("OpekepeSync.Crops"),
          },
        ]}
      />
      {!isKydIcognitoLink && (
        <LoadingButton
          onClick={onSubmit}
          isLoading={fetchState === FETCH_STATE.LOADING}
          isDisabled={isButtonDisabled}
          style={{
            width: "100%",
            backgroundColor: "#04303B",
            color: "#fff",
            borderRadius: "10px",
            "&:hover": { backgroundColor: "#04303B" },
          }}
        >
          {buttonText}
        </LoadingButton>
      )}
      {isKydIcognitoLink && (
        <SyncKydLinkSection
          userId={user?.id}
          opekepeLink={opekepeLink}
          onSync={onIcognitoSynced}
        />
      )}
    </div>
  );
};

export default OpekepeSync;
