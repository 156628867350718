import * as Yup from "yup";

export const equipmentSchema = (t) =>
  Yup.object().shape({
    alias: Yup.string().nullable(),
    machinery: Yup.number().nullable(),
    properties: Yup.object()
      .nullable()
      .shape({
        brandName: Yup.string().nullable(),
        capacity: Yup.object().nullable().shape({
          quantity: Yup.number().nullable(),
          units: Yup.string().nullable(),
        }),
        power: Yup.object().nullable().shape({
          quantity: Yup.number().nullable(),
          units: Yup.string().nullable(),
        }),
        pressure: Yup.object().nullable().shape({
          quantity: Yup.number().nullable(),
          units: Yup.string().nullable(),
        }),
        numberOfNozzles: Yup.number().nullable(),
        year: Yup.number().nullable(),
      }),
  });
