import { isEn } from "i18next-config";

export const varietyFullName = (
  field,
  options = { splitter: " ", startFrom: "taxonomy" }
) => {
  const { splitter: givenSplitter, startFrom: givenStartFrom } = options;

  const splitter = givenSplitter || " ";
  const startFrom = givenStartFrom || "taxonomy";

  let taxonomyName = isEn
    ? field?.varietyRead?.taxonomyNameEn
      ? field?.varietyRead?.taxonomyNameEn
      : ""
    : field?.varietyRead?.taxonomyNameGr
      ? field?.varietyRead?.taxonomyNameGr
      : "";
  let cropName = isEn
    ? field?.varietyRead?.cropNameEn
      ? field?.varietyRead?.cropNameEn
      : ""
    : field?.varietyRead?.cropNameGr
      ? field?.varietyRead?.cropNameGr
      : "";
  let varietyName = isEn
    ? field?.varietyRead?.varietyNameEn || ""
    : field?.varietyRead?.varietyNameGr || "";

  const areCropAndVarietySame = cropName === varietyName;

  let namesArray = [];
  if (startFrom === "taxonomy") {
    if (areCropAndVarietySame) {
      namesArray = [taxonomyName, cropName];
    } else {
      namesArray = [taxonomyName, cropName, varietyName];
    }
  } else if (startFrom === "crop") {
    if (areCropAndVarietySame) {
      namesArray = [cropName];
    } else {
      namesArray = [cropName, varietyName];
    }
  } else {
    namesArray = [varietyName];
  }

  return namesArray.join(splitter);
};

export const actionVarietyFullName = (action) => {
  if (!action) {
    return "";
  }
  const opekepeCultivationDetails = action.opekepeCultivationDetails ?? {};
  const cultivarTitle = opekepeCultivationDetails.cultivarTitle ?? "";
  const cultivationTitle = opekepeCultivationDetails.cultivationTitle ?? "";

  let varietyName = "";
  if (cultivationTitle) {
    varietyName = cultivationTitle;
    if (cultivarTitle) {
      varietyName += " / " + cultivarTitle;
    }
  } else {
    varietyName = cultivarTitle;
  }
  return varietyName;
};

export const fieldOwner = (field = {}) => {
  const { agronomistDetails, farmerDetails } = field;

  if (Array.isArray(farmerDetails) && farmerDetails.length) {
    return farmerDetails[0];
  }

  if (Array.isArray(agronomistDetails) && agronomistDetails.length) {
    return agronomistDetails[0];
  }

  return;
};

export const fieldOwnerName = (owner) => {
  if (owner) {
    return owner.lastName + " " + owner.firstName;
  }

  return;
};
