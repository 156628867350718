import { useCallback } from "react";
import { TextFieldCustom } from "components";

const checkAcceptedNumericValue = (value) => {
  return /^-?\d+(\.\d+)?$/.test(value);
};

const removeLeadingZeros = (value) => {
  // remove extra leading zeros, take into account negative numbers
  if (/^-?0+$/.test(value)) {
    return value.startsWith("-") ? "-0" : "0";
  }

  // allow leading zeros for decimal values less than 1
  if (value.includes(".")) {
    return value.replace(/^0+(?=\d)/, "");
  }

  return value.replace(/^0+/, "");
};

const applyDecimals = (value, decimals) => {
  let val = value;
  if (typeof decimals === "number") {
    val = parseFloat(val).toFixed(decimals);
  }
  val = String(val);
  return val;
};

const NumberField = ({
  className,
  id,
  defaultValue,
  endAdornment,
  label,
  helperText,
  isDisabled = false,
  isError = false,
  isFullWidth = true,
  isViewMode,
  decimals,
  name,
  onChange,
  placeholder,
  style,
  value,
  labelStyle,
  inputRef,
  ...props
}) => {
  const handleChange = useCallback(
    (e) => {
      let val = e.target.value;

      if (val === "0" || val === 0) {
        onChange?.("0");
        return;
      }
      if (!val) {
        onChange?.("");
        return;
      }

      if (!checkAcceptedNumericValue(val)) {
        return;
      }

      val = removeLeadingZeros(val);
      val = applyDecimals(val, decimals);

      onChange?.(val);
    },
    [onChange, decimals]
  );

  const handleBlur = useCallback(() => {
    if (!value) {
      onChange?.(0);
    }
  }, [onChange, value]);

  return (
    <TextFieldCustom
      {...props}
      id={id}
      name={name}
      type="number"
      label={label}
      placeholder={placeholder}
      helperText={helperText}
      value={value}
      defaultValue={defaultValue}
      isDisabled={isDisabled}
      isError={isError}
      isFullWidth={isFullWidth}
      isViewMode={isViewMode}
      endAdornment={endAdornment}
      className={className}
      style={style}
      labelStyle={labelStyle}
      inputRef={inputRef}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export default NumberField;
