import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useActionChoices } from "stores";
import {
  actionVarietyFullName,
  varietyFullName,
} from "utils/extractInfoFromResponses";
import { useActionModalGetContext } from "context/ActionModalProvider";
import { FETCH_STATE } from "constants/fetchState";
import Loader from "../Loader/Loader";
import NoContentFound from "../NoContentFound/NoContentFound";
import ActionReadyContent from "./content/ActionReadyContent";
import { ActionModalStyled } from "./common-styled/ActionModalStyled";

const ActionModal = ({ isOpen, onClose, selectedFields }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { fetchState, errorMessage, action } = useActionModalGetContext();
  const { fetchState: actionsFetchState } = useActionChoices();
  const isReady =
    fetchState === FETCH_STATE.SUCCESS && actionsFetchState.isSuccess;
  const isLoading =
    fetchState === FETCH_STATE.LOADING || actionsFetchState.isPending;

  const handleClose = () => {
    const params = new URLSearchParams(location.search);
    if (params.has("actionId")) {
      params.delete("actionId");
      navigate(
        location.pathname + params.toString() ? "?" + params.toString() : ""
      );
    }
    onClose?.();
  };

  const title = useMemo(() => {
    if (!selectedFields?.length) return;

    const fieldsName =
      selectedFields.length === 1
        ? selectedFields[0]?.name
        : `${selectedFields.length} ${t("Global.Field", { count: selectedFields.length })}`;

    const actionVarietyName = actionVarietyFullName(action.action);
    if (actionVarietyName) {
      return `${fieldsName} | ${actionVarietyFullName(action.action)}`;
    }
    return `${fieldsName} | ${varietyFullName(selectedFields[0], { splitter: " / " })}`;
  }, [t, selectedFields, action?.action]);

  return (
    <ActionModalStyled
      isOpen={isOpen}
      title={title}
      classNames={{
        wrapper: "action-modal action-modal-modal",
      }}
      onClose={handleClose}
    >
      {isLoading && <Loader size={50} />}
      {fetchState === FETCH_STATE.ERROR && (
        <NoContentFound text={errorMessage} />
      )}
      {isReady && (
        <ActionReadyContent
          selectedFields={selectedFields}
          onClose={handleClose}
        />
      )}
    </ActionModalStyled>
  );
};

export default ActionModal;
