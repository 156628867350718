import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, ClickAwayListener, useTheme } from "@mui/material";
import clsx from "clsx";
import { useSidebarsContext } from "context/SidebarsProvider";
import SideBarContainer from "../../sidebars/SideBarContainer";
import InboxSidebarContainer from "../inbox/InboxSidebarContainer";

const GlobalSidebarsContainer = () => {
  const { selected, closeSidebar } = useSidebarsContext();
  const theme = useTheme();

  const location = useLocation();

  useEffect(() => {
    if (typeof closeSidebar === "function") {
      closeSidebar();
    }
  }, [location.pathname, closeSidebar]);

  const isOpen = Boolean(selected);

  return (
    <>
      <ClickAwayListener onClickAway={closeSidebar}>
        <Box>
          <SideBarContainer
            isOpen={isOpen}
            wrapperStyle={{
              zIndex: theme.zIndex.drawer,
              position: "absolute",
            }}
            style={{ padding: "16px" }}
            size="lg"
            className="global-drawer"
          >
            {selected === "inbox" && <InboxSidebarContainer />}
          </SideBarContainer>
        </Box>
      </ClickAwayListener>
      <div
        className={clsx("global-drawer-background", { open: isOpen })}
        style={{
          zIndex: theme.zIndex.drawerBackground,
        }}
      />
    </>
  );
};

export default GlobalSidebarsContainer;
