import { styled } from "@mui/material";
import clsx from "clsx";
import { NormalText } from "components";
import PropTypes from "prop-types";

const TreeNodeStyled = styled("div")({
  display: "flex",
  gap: "6px",
  alignItems: "center",
  "& .expand-icon": {
    width: "10px",
  },
  "& input": {
    cursor: "pointer",
    "&.no-children": {
      marginLeft: "16px",
    },
  },
  "& p": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflowX: "hidden",
  },
});

const TreeNode = ({
  node,
  onToggle,
  onCheck,
  checkedNodes,
  isDisabled,
  className,
  classNames = {}, // Expected classNames: node,nodeButton,nodeInput,nodeText
}) => {
  const hasChildren = node.children && node.children.length > 0;
  const isChecked = checkedNodes.includes(node.value);
  const level = node.parentIdPath?.length ?? 0;

  return (
    <TreeNodeStyled
      className={clsx(className, classNames.node)}
      sx={{ paddingLeft: `${level * 20}px` }}
    >
      {hasChildren && (
        <button
          className={clsx("btn-unstyled", "expand-icon", classNames.nodeButton)}
          onClick={() => onToggle(node)}
        >
          {node.isExpanded ? "-" : "+"}
        </button>
      )}
      <input
        type="checkbox"
        disabled={isDisabled}
        checked={isChecked}
        onChange={() => onCheck(node)}
        className={clsx({ "no-children": !hasChildren }, classNames.nodeInput)}
      />
      <NormalText className={classNames.nodeText}>{node.label}</NormalText>
    </TreeNodeStyled>
  );
};

export default TreeNode;

const nodePropType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  parentIdPath: PropTypes.number.isRequired,
  isExpanded: PropTypes.bool,
});
nodePropType.children = PropTypes.arrayOf(nodePropType);

export const treeNodePropType = PropTypes.shape({
  node: nodePropType,
  onToggle: PropTypes.func,
  onCheck: PropTypes.func,
  isDisabled: PropTypes.bool,
  checkedNodes: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  style: PropTypes.object,
});

TreeNode.propTypes = treeNodePropType;
