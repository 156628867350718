const isQA = process.env.REACT_APP_ENV_QA;

const baseUrls = {
  cropVarieties: "/api/fields/crop-varieties",
  consents: "/api/user-consents",
  equipments: "/api/users/equipment",
  externalRequests: "/api/external-requests",
  fieldList: "/api/fields/users",
  fields: "/api/fields/fields",
  fieldSearch: "/api/fields/search",
  joinRequests: "/api/fields/join-requests",
  machineryInfrastructure: "/api/machinery-infrastructure",
  newsFeed: "/api/external-requests/content",
  notifications: "/api/notifications/my-settings",
  inbox: "/api/notifications/my-inbox",
  opekepe: "/api/opekepe",
  opekepeAuth: "/api/socials/auth/opekepe",
  redoc: "/api/redoc",
  schema: "/api/schema",
  search: "/api/search",
  socials: "/api/socials",
  soilComposition: "/api/fields/soil-composition",
  statistics: "/api/external-requests/statistics",
  superset: "/api/superset/dashboards/spd/",
  swagger: "/api/swagger",
  auth: "/api/authentication/jwt/token",
  users: "/api/users",
  wateringMechanism: "/api/fields/watering-mechanism",
  waterUsageLimits: "/api/fields/water-usage-limits",
};

const qaUrls = baseUrls;

const prodUrls = baseUrls;

export const URLS = isQA ? qaUrls : prodUrls;
