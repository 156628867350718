import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { api } from "api";
import { FETCH_STATE } from "constants/fetchState";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useToggle } from "hooks/useToggle";
import { convertToLatLng } from "utils/convertToLatLng";
import { fieldOwner, fieldOwnerName } from "utils/extractInfoFromResponses";
import { useFieldsContextActions } from "./FieldsProvider";

const fieldInfo = (field, isEn) => {
  return {
    id: field.id,
    ownerName: fieldOwnerName(fieldOwner(field)),
    name: field.name,
    plantationDate: field.plantationDate,
    numberOfRoots: field.numberOfRoots,
    squareMeters:
      typeof field.areaOfField === "number"
        ? Math.floor(field.areaOfField)
        : field.areaOfField,
    wateringMethod: isEn
      ? field.wateringMechanismRead?.wateringCategoryEn
      : field.wateringMechanismRead?.wateringCategoryGr,
    wateringMechanism: field.wateringMechanismRead?.id,
    cropVariety: isEn
      ? field.varietyRead?.varietyNameEn
      : field.varietyRead?.varietyNameGr,
    variety: field.variety,
    cropId: field.varietyRead?.cropId,
    varietyId: field.varietyRead?.varietyId,
    taxonomyId: field.varietyRead?.taxonomyId,
    coordinates: field.coordinates?.coordinates || [],
    coordsForMapImage: convertToLatLng(field.coordinates?.coordinates || []),
    isOpekepeConnected: field.inputSource !== 1,
  };
};

const ALLOWED_ACTIONS = {
  EDIT: "edit",
  REMOVE: "remove",
  REDIRECT: "redirect",
};
const FieldInfoModalContext = createContext({
  field: {
    id: null,
    ownerName: "",
    name: "",
    plantationDate: "",
    numberOfRoots: "",
    squareMeters: "",
    wateringMethod: "",
    wateringMechanism: "",
    cropVariety: "",
    variety: "",
    cropId: "",
    varietyId: "",
    taxonomyId: "",
    coordinates: [],
    coordsForMapImage: [],
    isOpekepeConnected: false,
  },
  allow: [],
  opekepeInfo: {
    area: "",
    atak: "",
    cartographicBackground: "",
    echoschemes: "",
    cultivars: [{ code: "", cultivar: "", cultivation: "", area: "" }],
    year: "",
    location: "",
    syncedDate: "",
  },
  isOpekepeConnected: false,
  fetchState: FETCH_STATE.IDLE,
  opekepeFetchState: FETCH_STATE.IDLE,
  actionState: FETCH_STATE.IDLE,
  error: "",
  isEditMode: false,
  setToEditMode: () => {},
  setToViewMode: () => {},
  updateFieldInfo: ({ values, onSuccess, onError }) => {},
  cancelFieldCollaboration: ({ onSuccess, onError }) => {},
  deleteSelectedField: ({ onSuccess, onError }) => {},
});

const FieldInfoModalProvider = ({
  children,
  id,
  allow,
  onEditSuccess,
  onCancelSuccess,
  onDeleteSuccess,
}) => {
  const { i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const { updateField, deleteField, cancelCollaboration } =
    useFieldsContextActions();

  const [fetchState, setFetchState] = useState(FETCH_STATE.IDLE);
  const [opekepeFetchState, setOpekepeFetchState] = useState(FETCH_STATE.IDLE);
  const [actionState, setActionState] = useState(FETCH_STATE.IDLE);
  const [error, setError] = useState();
  const [field, setField] = useState();
  const [opekepeInfo, setOpekepeInfo] = useState();

  const {
    isOpen: isEditMode,
    open: setToEditMode,
    close: setToViewMode,
  } = useToggle(false);

  // Fetch field info
  useEffect(() => {
    if (!id) return;
    setFetchState(FETCH_STATE.LOADING);
    api.fields
      .fetchOne({ fieldId: id })
      .then((res) => {
        const field = fieldInfo(res.data, isEn);
        setField(field);
        setFetchState(FETCH_STATE.SUCCESS);
      })
      .catch((err) => {
        console.error(err);
        setFetchState(FETCH_STATE.ERROR);
        setError(err);
      });
  }, [id, isEn]);

  // Fetch Opekepe field info if connected
  useEffect(() => {
    if (!field) return;
    if (!field.isOpekepeConnected) return;

    const fetchOpepekeInfo = async () => {
      setOpekepeFetchState(FETCH_STATE.LOADING);
      const { data, error } = await api.opekepe.fetchFieldInfo({
        id: field.id,
      });
      if (error) {
        setOpekepeFetchState(FETCH_STATE.ERROR);
        setError(error);
        return;
      }
      setOpekepeInfo(data);
      setOpekepeFetchState(FETCH_STATE.SUCCESS);
    };
    fetchOpepekeInfo();
  }, [field]);

  const updateFieldInfo = useCallback(
    ({ values, onSuccess, onError }) => {
      setActionState(FETCH_STATE.LOADING);
      // const convertedCoords = convertFromLatLng(values.coordinates);
      const plantationDate = moment(values.plantationDate).format("YYYY-MM-DD");
      const {
        cropId,
        taxonomyId,
        coordinates,
        varietyId,
        id: formFieldId,
        ...cleanField
      } = values;

      const fieldToPost = {
        ...cleanField,
        coordinates: { coordinates: [...values.coordinates], type: "Polygon" },
        plantationDate,
      };
      updateField({
        id,
        patchData: fieldToPost,
        onSuccess: (data) => {
          setActionState(FETCH_STATE.SUCCESS);
          setToViewMode();

          onSuccess?.(data);
          onEditSuccess?.(data);
        },
        onError: () => {
          setActionState(FETCH_STATE.ERROR);
          onError?.();
        },
      });
    },
    [id, updateField, setToViewMode, onEditSuccess]
  );

  const cancelFieldCollaboration = useCallback(
    ({ onSuccess, onError } = {}) => {
      setActionState(FETCH_STATE.LOADING);
      cancelCollaboration({
        id,
        onSuccess: () => {
          setActionState(FETCH_STATE.SUCCESS);
          onSuccess?.();
          onCancelSuccess?.();
        },
        onError: () => {
          setActionState(FETCH_STATE.ERROR);
          onError?.();
        },
      });
    },
    [id, cancelCollaboration, onCancelSuccess]
  );

  const deleteSelectedField = useCallback(
    ({ onSuccess, onError } = {}) => {
      setActionState(FETCH_STATE.LOADING);
      deleteField({
        id,
        onSuccess: () => {
          setActionState(FETCH_STATE.SUCCESS);
          onSuccess?.();
          onDeleteSuccess?.();
        },
        onError: () => {
          setActionState(FETCH_STATE.ERROR);
          onError?.();
        },
      });
    },
    [id, deleteField, onDeleteSuccess]
  );

  const value = useMemo(() => {
    return {
      field,
      allow,
      opekepeInfo,
      fetchState,
      opekepeFetchState,
      actionState,
      error,
      isEditMode,
      setToEditMode,
      setToViewMode,
      updateField: updateFieldInfo,
      cancelCollaboration: cancelFieldCollaboration,
      deleteField: deleteSelectedField,
    };
  }, [
    field,
    allow,
    opekepeInfo,
    fetchState,
    opekepeFetchState,
    actionState,
    error,
    isEditMode,
    setToEditMode,
    setToViewMode,
    updateFieldInfo,
    cancelFieldCollaboration,
    deleteSelectedField,
  ]);

  return (
    <FieldInfoModalContext.Provider value={value}>
      {children}
    </FieldInfoModalContext.Provider>
  );
};
const useFieldInfoModalContext = () => {
  const context = useContext(FieldInfoModalContext);

  if (!context) {
    throw new Error(
      "useFieldInfoModalContext must be used within a FieldInfoModalContext"
    );
  }
  return context;
};

export { FieldInfoModalProvider, useFieldInfoModalContext, ALLOWED_ACTIONS };
