import { useActionChoices } from "stores";
import { Loader } from "components";
import { useSelectedFieldContext } from "context/SelectedFieldProvider";
import { varietyFullName } from "utils/extractInfoFromResponses";
import AnalysisReadyContent from "./content/AnalysisReadyContent";
import { ActionModalStyled } from "./common-styled/ActionModalStyled";

const AnalysisModal = ({ isOpen, onClose, type }) => {
  const { selectedField } = useSelectedFieldContext();
  const { fetchState } = useActionChoices();

  const title =
    selectedField?.name +
    " / " +
    varietyFullName(selectedField, { splitter: " / " });

  const handleClose = () => {
    onClose();
  };

  return (
    <ActionModalStyled
      isOpen={isOpen}
      title={title}
      classNames={{ wrapper: "action-modal action-modal-modal" }}
      onClose={handleClose}
    >
      {fetchState.isPending && <Loader size={50} />}
      {fetchState.isSuccess && (
        <AnalysisReadyContent
          selectedField={selectedField}
          onClose={handleClose}
          type={type}
        />
      )}
    </ActionModalStyled>
  );
};

export default AnalysisModal;
