import { useTranslation } from "react-i18next";
import moment from "moment";
import { FETCH_STATE } from "constants/fetchState";
import Banner from "components/banner/Banner";
import NormalText from "components/Typography/NormalText";

const OpekepeSynced = ({ fetchState, data }) => {
  const { t } = useTranslation();
  return (
    <div className="sync-step">
      <div className="sync-step-title">
        <NormalText>{t("OpekepeSync.Fields.Title")}</NormalText>
      </div>
      <div className="sync-step-content">
        {fetchState === FETCH_STATE.ERROR && (
          <Banner.Error text={t("OpekepeSync.Fields.ErrorFetchSyncStatus")} />
        )}
        {fetchState === FETCH_STATE.SUCCESS && (
          <>
            <Banner.Success text={t("OpekepeSync.Fields.Success")} />
            <div className="step-content-row">
              <NormalText>{t("OpekepeSync.DateTime")}</NormalText>
              <NormalText>
                {moment(data.dateSynced)?.format("MMMM Do YYYY, h:mm")}
              </NormalText>
            </div>
            <div className="step-content-row">
              <NormalText>{t("OpekepeSync.TotalFields")}</NormalText>
              <NormalText>{data.totalFields}</NormalText>
            </div>
            <div className="step-content-row">
              <NormalText>{t("OpekepeSync.Crops")}</NormalText>
              <NormalText>{data.totalCrops}</NormalText>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default OpekepeSynced;
