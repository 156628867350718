import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Loader, MapImage } from "components";
import { FETCH_STATE } from "constants/fetchState";
import { useFieldInfoModalContext } from "context/FieldInfoModalProvider";
import { fieldDimensionsRead } from "utils/fieldDimensions";
import Layout from "../common/FieldInfoModalLayout";
import Info, { InfoRow } from "../common/Info";

const FieldOpekepeInfo = () => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");
  const { field, opekepeInfo, opekepeFetchState } = useFieldInfoModalContext();
  const { coordsForMapImage } = field || {};

  const info = useMemo(
    () =>
      Array.isArray(opekepeInfo) ? opekepeInfo[opekepeInfo.length - 1] : {},
    [opekepeInfo]
  );
  return (
    <>
      {opekepeFetchState === FETCH_STATE.LOADING && <Loader />}
      {opekepeFetchState === FETCH_STATE.SUCCESS && (
        <Layout.InfoWrapperContainer>
          <Layout.MapViewContainer>
            <MapImage
              polygon={coordsForMapImage}
              style={{ width: "100%", height: "100%" }}
            />
          </Layout.MapViewContainer>
          <Layout.InfoContainer>
            <InfoRow>
              <Info
                label={t("FieldInfo.Opekepe.SyncDate")}
                value={info?.syncedDate}
              />
              <Info
                label={t("FieldInfo.Opekepe.Application")}
                value={info?.year}
              />
              <Info
                label={t("FieldInfo.Opekepe.Location")}
                value={info?.location}
              />
            </InfoRow>
            <InfoRow>
              <Info
                label={t("FieldInfo.Opekepe.FarmerCode")}
                value={info?.atak}
              />
              <Info
                label={t("FieldInfo.Opekepe.CartographicCode")}
                value={info?.cartographicBackground}
              />
              <Info
                label={t("FieldInfo.Opekepe.EcoSchemes")}
                value={info?.ecoSchemes}
              />
            </InfoRow>
            <InfoRow>
              <Info label={t("FieldInfo.Opekepe.Cultivars")}>
                <ul style={{ paddingLeft: "1rem" }}>
                  {info?.cultivars?.map((cul) => {
                    const value = `${cul.cultivation} | ${cul.cultivar} | ${fieldDimensionsRead(t, isEn, cul.area)}`;
                    return (
                      <li>
                        <InfoRow>
                          <Info value={value} />
                        </InfoRow>
                      </li>
                    );
                  })}
                </ul>
              </Info>
            </InfoRow>
          </Layout.InfoContainer>
        </Layout.InfoWrapperContainer>
      )}
    </>
  );
};

export default FieldOpekepeInfo;
