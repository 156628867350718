import { Avatar, styled } from "@mui/material";

const AvatarStyled = styled(Avatar)({
  fontWeight: 500,
  fontSize: "14px",
  color: "black",
  backgroundColor: "rgba(229, 244, 244, 1)",
  width: "25px",
  height: "25px",
  boxShadow: "0px 2px 4px 0px rgba(7, 65, 139, 0.25)",
});

const AvatarCustomUnstyled = ({ name, nameSplitter = " ", className }) => {
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: "rgba(229, 244, 244, 1)",
      },
      children: `${name.split(nameSplitter)[0][0] || ""}${
        name.split(nameSplitter)[1][0] || ""
      }`,
    };
  }
  return <AvatarStyled {...stringAvatar(name)} className={className} />;
};

const AvatarCustom = styled(AvatarCustomUnstyled)();

export default AvatarCustom;
