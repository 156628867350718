import { useCallback } from "react";
import { Field, useField, useFormikContext } from "formik";
import NumberField from "components/number-field/NumberField";

const FormNumberInput = ({
  name,
  onChange,
  shouldHaveErrorBehaviorAfterSubmit,
  helperText,
  decimals,
  ...props
}) => {
  const [, meta] = useField(name);
  const metaError = name ? meta.error : null;
  const { submitCount, setFieldValue } = useFormikContext();

  const shouldDisplayError = shouldHaveErrorBehaviorAfterSubmit
    ? submitCount && metaError
    : metaError;

  const finalHelperText = (shouldDisplayError && metaError) || helperText;

  const handleChange = useCallback(
    (val) => {
      if (typeof onChange === "function") {
        onChange(val);
      } else {
        setFieldValue(name, val);
      }
    },
    [name, onChange, setFieldValue]
  );

  return (
    <Field
      name={name}
      as={NumberField}
      helperText={finalHelperText}
      isError={shouldDisplayError}
      onChange={handleChange}
      {...props}
    />
  );
};

export default FormNumberInput;
