import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material";
import { OPEKEPE_SYNC_SCOPE } from "constants/opekepe";
import { userFullName } from "utils/namingUtils";
import Modal from "components/Modals/Modal";
import OpekepeSync from "components/opekepe-sync/OpekepeSync";

const ModalStyled = styled(Modal)({
  "& .opekepe-sync-wrapper": {
    height: "max-content",
    width: "500px",
    "& .opekepe-sync-body": {
      display: "flex",
      justifyContent: "center",
      padding: "2rem",
    },
  },
});

const OpekepeSyncModal = ({ isOpen, onClose, user }) => {
  const { t } = useTranslation();
  const userMapped = useMemo(
    () => ({
      id: user?.id,
      name: userFullName({ user }),
      vat: user?.taxNumber,
    }),
    [user]
  );

  const [isSynced, setIsSynced] = useState(false);
  const handleSyncSuccess = useCallback(() => {
    setIsSynced(true);
  }, []);

  const handleClose = () => {
    if (isSynced) {
      window.location.reload();
      return;
    }
    onClose?.();
  };

  return (
    <ModalStyled
      isOpen={isOpen}
      onClose={handleClose}
      classNames={{
        wrapper: "opekepe-sync-wrapper",
        body: "opekepe-sync-body",
      }}
      title={t("KydTable.FieldInfoTitle")}
    >
      <OpekepeSync
        scope={OPEKEPE_SYNC_SCOPE.KYD}
        user={userMapped}
        isFullWidth
        onSyncSuccess={handleSyncSuccess}
      />
    </ModalStyled>
  );
};

export default OpekepeSyncModal;
